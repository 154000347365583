<template>
  <div class="spec-lump-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
    name: 'specLump',
    data() {
      return {

      }
    }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>