<template>
  <div>
    <van-popup v-model="show" @closed="closed" position="bottom" round :style="{ minHeight: '35%', }">
      <div style="padding: 1rem; display: flex;">
        <van-image :src="good.product[0].image_path" fit="cover" class="img" radius="5" />
        <div class="width-100" style="margin-left: 0.5rem;">
          <div class="flex jcsb width-100" style="font-size: 0.9rem;">
            <div>{{ good.product[0].title }}</div>
            <div @click="closed">X</div>
          </div>
          <div style="font-size: 0.9rem; color: #F00; margin-top: 1rem;">{{ good.specs[selectIndex].price }}点</div>
          <div style="font-size: 0.9rem; color: #666; ">市场价:{{ good.specs[selectIndex].market_price }}点</div>
        </div>
      </div>
      <div style="padding-bottom:1rem ; ">
        <div style="overflow-y: scroll; max-height: 20rem;">
          <div v-for="(item, index) in  good.specs " @click="selectItem(index)">
            <div style="height: 0.8rem; font-size: 0.8rem;color: #222;margin-left: 1rem;margin-bottom: -0.4rem;">
            </div>
            <div :class="selectIndex == index ? 'selectTag' : 'tag'"> {{
              item.name }} </div>
          </div>
        </div>
        <div class="flex p-10 jcsb">
          <div>
          </div>
          <div>
            <van-stepper v-model="value" min="1" @change="change" />
          </div>
        </div>
      </div>
      <van-button  class="foot-btn" @click="buy" style="font-size: 1rem;">确认</van-button>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  name: "",
  props: {
    good: {
      type: Object,
    },
  },
  data() {
    return {
      selectIndex: 0,
      show: true,
      value: 1,
    };
  },
  components: {},
  methods: {
    closed() {
      this.$emit("closed", false);
    },
    // 步进器事件
    change(value) {
      this.value = value;
    },
    // 购买
    buy() {
      // console.log(this.good);
      // 添加一个count属性
      if (localStorage.getItem("nickname")) {
        // this.$set(this.good, "count", this.value);
        let obj = {
          index: this.selectIndex,
          cont: this.value
        }
        this.$emit('seleceItem', obj)
      } else {
        Dialog.confirm({
          title: "未登录",
          message: "是否前去登陆",
        })
          .then(() => {
            this.$router.push("/Login");
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    selectItem(index) {
      this.selectIndex = index
    }
  },
  mounted() {
    // console.log(this.good);
    // 进入页面显示在顶部
    window.scroll(0, 0);
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.img {
  width: 5rem;
  height: 5rem;
}

.tag {
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid #666;
  color: #666;
  margin: 0.2rem 1rem;
}
.foot-btn {
  width: 90%;
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
  line-height: normal; //安卓字体偏上
  text-align: center;
  border-radius: 1.5rem;
  color: #fff;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  font-size: 1.1rem;
   margin: 0 5% 1.5rem 5%;
}
.selectTag {
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin: 0.2rem 1rem;
  border: 1px solid #D33B2E;
  color: #D33B2E;
}

.btn {
  position: absolute;
  bottom: 0;
  height: 2.5rem;
}
</style>