import { Dialog, Toast } from "vant";
import httpApi from "../../http/api";
import router from "../../router/index";
import { sendSelectTypeOptions } from "../../config/options";
/**
 * 返回对应的标识符
 * @param {*} sendTypeStr
 * @returns
 */
export function getSendSelectType(sendTypeStr) {
  const sendSelectTypeItem = sendSelectTypeOptions.find((item) => sendTypeStr.includes(item.name));
  return sendSelectTypeItem ? sendSelectTypeItem.sendSelectType : 0;
}
export function getSendTypeText(value) {
  return (
    value &&
    value.reduce((pre, item) => {
      return pre + item + " ";
    }, "")
  );
}
/**
 * 判断当前数组为非空数组
 * @param {*} arr 需要判定的数组
 * @returns
 */
export function isNoEmptyArr(arr) {
  return Array.isArray(arr) && arr.length > 0;
}
/**
 * 查询与当前地址匹配的配送规则
 * @param {*} formData {
 *  cityCode,  城市编码
 *  quantitys,  购买数量
 *  distributionRuleId, 当前配送规则id
 *  specId,  规格id
 *  addrId: dsId  配送地址 id
 *  }
 * @param {*} brandId 品牌id
 *
 * @return {Promise}
 */
// {
//   "is_distribution": "1", // 是否可以配送
//   "can_take": "0", // 门店自提
//   "can_ship": "0", // 商家配送
//   "can_same": "1", // 快递
//   "validate_delivery_dates": [], // 商家自配送时间集合
//   "validate_take_dates": [], // 用户自提时间集合
//   "validate_same_row": [], // 快递
//   "delivery_text": "0"
// }
export async function queryDistributionRules(formData, brandId) {
  const res = await httpApi.queryDistributionRules(formData);
  if (res.code != 0) {
    if (res.msg) {
      Toast(res.msg);
    }
    return false;
  }
  const resultData = {
    validate_delivery_dates: [],// 商家配送数据
    sendSelectType: 0, //1 自提  2 快递 3 商户
    isDistribution: 0,     // 是否可配送   0不可配送  1 可配送
    shopInfo: null,
    deliveryRules: [], // 配送规则
    deliveryInfo: {
      sendTypeStr: "", // 配送信息文字描述
      shipDate: "", // 配送日期
      shipTimeText: "", //配送时间
      deliveryAmount: "" // 配送费用
    }, // 配送信息
    showAddcard: true,
  };
  if (res.data) {
    resultData.isDistribution = res.data.is_distribution;
    if (res.data.can_same == "1") {

      if (res.data.validate_same_row && res.data.validate_same_row.length > 0) {
        resultData.showAddcard = true
        let rules = { text: "快递配送", children: [] };
        //validate_same_row
        rules.children.text = "运费";
        let child3 = { text: "运费", children: [] };
        if (res.data.validate_same_row[0].delivery_amount == "0") {
          resultData.deliveryInfo.sendTypeStr = "快递配送 免运费";
          resultData.deliveryInfo.deliveryAmount = 0;
        } else {
          resultData.deliveryInfo.sendTypeStr =
            "快递配送 运费" + "" + res.data.validate_same_row[0].delivery_amount + " 点"; //res.data.validate_same_row[0].delivery_amount
          resultData.deliveryInfo.deliveryAmount = res.data.validate_same_row[0].delivery_amount;
        }
        resultData.sendSelectType = 2;
        res.data.validate_same_row.forEach((element) => {
          let children = { text: "" };
          if (element.delivery_amount == "0") {
            children.text = "免运费";
          } else {
            children.text = element.delivery_amount + '点'; //element.delivery_amount
          }
          child3.children.push(children);
        });
        rules.children.push(child3);
        resultData.deliveryRules.push(rules);
      }

    }
    if (res.data.can_take == "1") {

      if (res.data.validate_take_dates && res.data.validate_take_dates.length > 0) {
        resultData.showAddcard = false
        let rules1 = { text: "门店自提", children: [] };
        //validate_take_dates
        res.data.validate_take_dates.forEach((element) => {
          //validate_take_dates
          let child = { text: "", children: [] };
          child.text = element.date;
          child.value = element.date;
          let time = element.validate_take_times; //validate_take_times
          if (time) {
            time.forEach((element) => {
              let children = { text: "" };
              children.text = element;
              child.children.push(children);
            });
          }
          rules1.children.push(child);
        });
        resultData.deliveryInfo.sendTypeStr =
          "门店自提 " + rules1.children[0].text + " " + rules1.children[0].children[0].text;
        resultData.deliveryInfo.shipDate = rules1.children[0].value;
        resultData.deliveryInfo.shipTimeText = rules1.children[0].children[0].text;
        resultData.sendSelectType = 1;
        resultData.deliveryRules.push(rules1);
      }
      // const shopInfo = await getShopInfo(formData.cityCode, brandId);
      // resultData.shopInfo = shopInfo;
    }
    if (res.data.can_ship == "1") {
      if (res.data.validate_delivery_dates && res.data.validate_delivery_dates.length > 0) {
        resultData.showAddcard = false
        let rules2 = { text: "商家配送", children: [] };
        resultData.validate_delivery_dates = res.data.validate_delivery_dates
        res.data.validate_delivery_dates.forEach((element) => {
          let child = { text: "", children: [] };
          child.text = element.date;
          child.value = element.delivery_amount;
          let time2 = JSON.parse(element.validate_delivery_times);
          if (time2) {
            time2.forEach((element) => {
              let children = { text: "" };
              children.text = element;
              child.children.push(children);
            });
          }
          rules2.children.push(child);
        });
        if (res.data.validate_delivery_dates[0].delivery_amount == "0") {
          resultData.deliveryInfo.sendTypeStr =
            "商家配送 " + rules2.children[0].text + " " + rules2.children[0].children[0].text + ' 免运费';
        } else {
          resultData.deliveryInfo.sendTypeStr =
            "商家配送 " + rules2.children[0].text + " " + rules2.children[0].children[0].text + ' 运费' + res.data.validate_delivery_dates[0].delivery_amount + '点';
        }
        resultData.deliveryInfo.shipDate = rules2.children[0].text;
        resultData.deliveryInfo.shipTimeText = rules2.children[0].children[0].text;
        resultData.deliveryInfo.deliveryAmount = res.data.validate_delivery_dates[0].delivery_amount;
        resultData.sendSelectType = 3;
        resultData.deliveryRules.push(rules2);
      }

    }
  }
  if (resultData.deliveryRules.length == 0 || resultData.isDistribution == 0) {
    return false
  } else
    return resultData;
  // this.setSendTypeTitle(false);
}
/**
 * 获取商家地址信息
 * @param {*} cityCode 城市标识
 * @param {*} brandId 品牌标识
 * @return {Object} {
 *  address: 商铺地址
 *  shopId: 商铺标识
 *  shopName: 商铺名称
 * }
 */
export async function getShopInfo(cityCode, brandId) {
  const res = await httpApi.getShopList(cityCode, brandId);
  if (res.code === 0) {
    const shopCollect = res.data;
    if (isNoEmptyArr(shopCollect.shops)) {
      const { address, shop_id, shop_name } = shopCollect.shops[0];
      return {
        address: address,
        shopId: shop_id,
        shopName: shop_name
      };
    }
  }
}
export async function getShopListInfo(cityCode, brandId) {
  const res = await httpApi.getShopList(cityCode, brandId);
  if (res.code === 0) {
    const shopCollect = res.data;
    if (isNoEmptyArr(shopCollect.shops)) {
      return shopCollect.shops;
    }
  } else {
    Toast(res.msg);
  }
}
export function getTypeConfirm(value) {
  if (!value) return;
  const resultData = {
    sendTypeStr: "",
    shipDate: "",
    shipTimeText: "",
    deliveryAmount: ""
  };
  let isSend = false;
  value.forEach((element, index) => {
    resultData.sendTypeStr += element + " ";
    if (index === 1) {
      resultData.shipDate = element;
      if (element.includes("运费")) {
        isSend = true;
      }
    }
    if (index === 2) {
      if (isSend) {
        if (element == "免运费") {
          resultData.deliveryAmount = 0;
          resultData.sendTypeStr = resultData.sendTypeStr.replace("运费", " ");
        } else {
          resultData.deliveryAmount = element;
        }
      }
      resultData.shipTimeText = element;
    }
  });
  return resultData;
}

export function isLogin() {
  if (localStorage.getItem("nickname")) {
    return true;
  } else {
    Dialog.confirm({
      title: "未登录",
      message: "是否前去登陆"
    })
      .then(() => {
        router.push("/Login");
      })
      .catch(() => {
        // on cancel
      });
  }
}
/**
 * 上送地址到第三方平台
 * @param {*} item 选择的地址参数Item
 * @returns
 */
export async function addCakeAddress(item) {
  if (item.dsId == 0) {
    let form = {
      addr: item.address + item.houseNum,
      area: item.addressQname,
      cityName: item.addressCname,
      isDefault: item.defaultFlag,
      lat: item.lat,
      lng: item.lng,
      name: item.recipient,
      phone: item.recipientMobile,
      addressId: item.id
    };
    const res = await httpApi.addCakeAddress(form);
    if (res.code === 0) {
      return {
        dsUserId: res.data.user_id,
        dsId: res.data.id
      };
    } else {
      Toast(res.msg);
      return {
        dsUserId: "",
        dsId: ""
      };
    }
  } else {
    return {
      dsUserId: item.dsUserId,
      dsId: item.dsId
    };
  }
}
export function addressTag() {
  Dialog.confirm({
    title: "提示",
    message: "请先设置收货地址",
    confirmButtonText: "去设置"
  }).then(() => {
    router.push("/EditAddress");
  });
}
/**
 * 规格数据映射
 */
export function specItemInfoMap() { }
const specifications = [
  {
    id: "1200787", // 规格id
    specifications: "商品规格军绿色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i1/2078046920/O1CN01ItGKoO20zPKegLYWi_!!2078046920.jpg_110x10000Q75.jpg_.webp",
    showPrice: 423, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "10", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "970", // 规格id
    specifications: "【热卖款】征服者大号蛋糕", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i1/2078046920/O1CN01ItGKoO20zPKegLYWi_!!2078046920.jpg_110x10000Q75.jpg_.webp",
    showPrice: 532, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "3", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "980", // 规格id
    specifications: "23款嘉际L【双层丝圈脚垫】【三排】", // 规格描述
    specImage:
      "//gw.alicdn.com/bao/uploaded/i3/2078046920/O1CN01dWXxnf20zPKesTGCh_!!2078046920.jpg_110x10000Q75.jpg_.webp",

    showPrice: 131, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "5", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "990", // 规格id
    specifications: "19-22年嘉际【咖啡单层脚垫】【三排】", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i4/2078046920/O1CN01kSdwAn20zPKdSfedS_!!2078046920.jpg_110x10000Q75.jpg_.webp",
    showPrice: 654, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "1000", // 规格id
    specifications: "宝骏730【单独主驾驶】米色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i3/2215057824/O1CN01KmlGyy27fRPi1eDZY_!!2215057824.jpg_110x10000Q75.jpg_.webp",
    showPrice: 111, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "1100", // 规格id
    specifications: "宝骏730【前+中+三排】米色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i3/2215057824/O1CN01qNtvyE27fRQByQZ0M_!!2215057824.jpg_360x10000Q75.jpg_.webp",
    showPrice: 51, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "1001", // 规格id
    specifications: "宝骏730【主驾驶+副驾驶】棕色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i3/2215057824/O1CN01jbgyFl27fRSJg3nbq_!!2215057824.jpg_360x10000Q75.jpg_.webp",
    showPrice: 766, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "1002", // 规格id
    specifications: "宝骏730【全车】棕色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i4/2215057824/O1CN01XzqqsN27fRPuJlFyR_!!2215057824.jpg_360x10000Q75.jpg_.webp",
    showPrice: 864, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "1003", // 规格id
    specifications: "宝骏730【全车】棕色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i4/2215057824/O1CN01XzqqsN27fRPuJlFyR_!!2215057824.jpg_360x10000Q75.jpg_.webp",
    showPrice: 123, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "1004", // 规格id
    specifications: "宝骏730【全车】棕色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i4/2215057824/O1CN01XzqqsN27fRPuJlFyR_!!2215057824.jpg_360x10000Q75.jpg_.webp",
    showPrice: 123, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "1005", // 规格id
    specifications: "宝骏730【全车】棕色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i4/2215057824/O1CN01XzqqsN27fRPuJlFyR_!!2215057824.jpg_360x10000Q75.jpg_.webp",
    showPrice: 123, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  },
  {
    id: "1006", // 规格id
    specifications: "宝骏730【全车】棕色", // 规格描述
    specImage:
      "https://gw.alicdn.com/bao/uploaded/i4/2215057824/O1CN01XzqqsN27fRPuJlFyR_!!2215057824.jpg_360x10000Q75.jpg_.webp",
    showPrice: 123, // 售卖价
    costPrice: 50, // 成本价
    batchId: "350", // 批次id
    status: "1", // 上架状态  1 上架， 2 下架  3 补货
    limitNum: "1", // 限购
    bannerList: [
      // banner 图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/fcea706d5a76464babbc050f60f485fc2.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/a9ecd39a48d04bd4b6629db61b021bbe7.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/941bb3b193f0449b8ebc31d04f49c4b38.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/735ca18364104967b7a17f213511362e9.jpg"
    ],
    htmEditorList: [
      // 详情图数组
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf71c9586c514b23b9c351c7a43fc9c1内野军旅双肩包_01.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/8ae661013da0460badd4266a015f9070内野军旅双肩包_03.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/646a59b00ab84cc9a164b979060f8178内野军旅双肩包_02.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/1fbfb78cecdb4222b4aaec637eb09dbb内野军旅双肩包_07.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/7f2135f8ce0e4548a7452515b2fdca5f内野军旅双肩包_04.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/cf25ca8ddc4a480d9fa47497942c3255内野军旅双肩包_06.jpg",
      "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/79f49cfe101947e1982a44134f0372be内野军旅双肩包_05.jpg"
    ],
    coverImage: "https://shijieux.oss-cn-beijing.aliyuncs.com/ecms/2023/12/28/684ff56ea5de4836b997d750f331c1a2绿色.png" // 主图
  }
];

export function getSpecInfo() {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(specifications), 500);
  });
}
