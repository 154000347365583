<template>
  <div class="details-spec-item" @click="handleClick">
    <span v-if="label" class="details-spec-item-label">{{ label }}</span>
    <div class="details-spec-item-text" :style="{justifyContent: !label ? 'space-between' : 'right'}">
      <span> {{ text }}</span>
      <van-icon class="details-spec-item-text-icon" name="arrow" color="#999" size="1rem" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    text: {
      type: String
    }
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
