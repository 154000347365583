<template>
  <div class="selected-spec-header">
    <div class="selected-spec-header-content">
      <div class="img-content">
        <img :src="selectedSpec.coverImage" />
      </div>
      <div class="text-content">
        <span class="text-price">{{selectedSpec.showPrice }}</span>
        <span class="text-prefix">点</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'specHeader',
  props: {
    selectedSpec: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>