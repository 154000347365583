import router from "../../router/index";
import httpApi from "../../http/api";
import { Dialog, Toast } from "vant";
import { sendSelectTypeOptions } from "../../config/options";
export function buyNow(queryData) { }

export function goBrandPage(goodsInfo) {
  if (goodsInfo && goodsInfo.id) {
    const { category1Id, category2Id, brandName } = goodsInfo;
    router.push({
      path: "/ProductList",
      query: {
        brandName: brandName,
        category1Id: category1Id,
        category2Id: category2Id
      }
    });
  } else {
    Toast("商品信息有误，请重新选择");
  }
}
export function addressTag() {
  Dialog.confirm({
    title: "提示",
    message: "请先设置收货地址",
    confirmButtonText: '去设置'
  }).then(() => {
    this.$router.push("/EditAddress");
  })
}
/**
 * 加入购物车
 * @param {*} baseData 基本信息
 * @param {*} deliveryData 配送信息
 * @param {*} specsData 规格信息
 */
export async function submitBuyGoodsInfo(type, baseData, deliveryData, specsData, goodsData) {
  const paramsData = formatParams(baseData, deliveryData, specsData, goodsData);
  switch (type) {
    case "addMyCart": // 添加购物车
      const res = await httpApi.addShop(paramsData);
      if (res.code == 1) {
        Toast(res.msg);
        return;
      }
      Toast.success("加入购物车成功");
      break;
    case "buyNow": // 立即购买
      const buyNowRes = await httpApi.prePurchase(paramsData);
      if (buyNowRes.code === 0) {
        router.push({
          path: "/confirmOrder",
          query: {
            type: 2,
            code: buyNowRes.data
          }
        });
        return true;
      } else {
        return false;
      }
      break;
    default:
      break;
  }
}
//       leaveWordText: this.leaveWordText, // 留言信息
//notesText: this.notesText, // 备注信息
export function formatParams(baseData, deliveryData, specsData, goodsData) {
  const { userId, cardId, cityCode, productId, pruductFlag } = baseData;
  const { id: specId, count } = specsData;
  const { addressInfo, deliveryInfo, shopInfo, sendSelectType, leaveWordText, notesText } = deliveryData;
  const { deliveryAmount, shipDate, shipTimeText } = deliveryInfo || {};
  const { addressId, dsUserId, dsId } = addressInfo || {};
  const { shopDetail, shopId, shopName } = shopInfo || {};
  const sendTypeItem = sendSelectTypeOptions.find((item) => item.sendSelectType === sendSelectType);
  const shipType = sendTypeItem ? sendTypeItem.shipType : "same";
  return {
    productFlag: goodsData.productFlag, // 三方 自营
    distributionRuleId: goodsData.distributionRuleId,
    userId: userId, // 用户id *
    cardId: cardId, // 福利卡id *
    cityCode: cityCode, // 城市编码 *
    productId: productId, // 商品id *
    skuId: specId, // 规格id *
    productNum: count, // 商品数量 *
    blessings: leaveWordText,//祝福语
    buyerMsg: notesText,//备注
    addressId: addressId, // 配送地址id *
    dsUserId: dsUserId, // *
    dsId: dsId, // *
    postMoney: deliveryAmount, // 配送费 *
    shipType: shipType, // 配送类型 *
    shipDate: shipDate, // 配送日期 *
    shipTimeText: shipTimeText, // 配送时间 *
    shopDetail: shopDetail, // 商家地址 *
    shopId: shopId, // 商家id *
    shopName: shopName // 商家名称 *
  };
}

/**
 * 获取商品详情
 * @param {*} params {
 * cityCode, 城市编码
 * cardId, 卡id
 * brandId, 品牌id
 * productId 商品id
 * }
 */
export async function getGoodsDetail(params, specId) {
  const res = await httpApi.queryAllGoodsDetail(params);
  if (res.code === 0) {
    let specsArr = null;
    if (res.data.productType === '0' && res.data.venueList.length > 0 && res.data.venueList[0].ticketPriceList.length > 0) {
      specsArr = res.data.venueList[0].ticketPriceList;
    } else {
      specsArr = res.data.specificationList;
    }
    console.log('aaaaaaa  ', specsArr);
    let defaultSpecs = null
    if (specId && specsArr.length > 0) {
      specsArr.forEach(element => {
        if (specId == element.id) {
          defaultSpecs = element
        }
      });
    }
    return {
      specsArr: specsArr, // 规格信息数组
      defaultSpecs: defaultSpecs ? defaultSpecs : Array.isArray(specsArr) && specsArr.length > 0 ? specsArr[0] : null,
      target: res.data
    };
  } else {
    Toast.loading({
      message: res.msg,
      forbidClick: true,
      loadingType: "spinner",
      duration: "800"
    });
    return null
  }
}



export function goMyCart(type = 1) {
  router.push({
    path: "/myCart",
    query: { type }
  });
}
/**
 * 上送地址到第三方平台
 * @param {*} item 选择的地址参数Item
 * @returns
 */
export async function addCakeAddress(item) {
  if (item.dsId == 0) {
    let form = {
      addr: item.address + item.houseNum,
      area: item.addressQname,
      cityName: item.addressCname,
      isDefault: item.defaultFlag,
      lat: item.lat,
      lng: item.lng,
      name: item.recipient,
      phone: item.recipientMobile,
      addressId: item.id
    };
    const res = await httpApi.addCakeAddress(form);
    if (res.code === 0) {
      return {
        dsUserId: res.data.user_id,
        dsId: res.data.id
      };
    } else {
      Toast(res.msg);
      return {
        dsUserId: "",
        dsId: ""
      };
    }
  } else {
    return {
      dsUserId: item.dsUserId,
      dsId: item.dsId
    };
  }
}
export async function queryDistributionRules(formData, brandId) {
  const res = await httpApi.queryDistributionRules(formData);
  if (res.code != 0) {
    if (res.msg) {
      Toast(res.msg);
    }
    return false;
  }
  const resultData = {
    validate_delivery_dates: [],// 商家配送数据
    sendSelectType: 0, //1 自提  2 快递 3 商户
    isDistribution: 0,     // 是否可配送   0不可配送  1 可配送
    shopInfo: null,
    deliveryRules: [], // 配送规则
    deliveryInfo: {
      sendTypeStr: "", // 配送信息文字描述
      shipDate: "", // 配送日期
      shipTimeText: "", //配送时间
      deliveryAmount: "" // 配送费用
    }, // 配送信息
    showAddcard: true,
  };
  if (res.data) {
    resultData.isDistribution = res.data.is_distribution;
    if (res.data.can_same == "1") {
      if (res.data.validate_same_row && res.data.validate_same_row.length > 0) {
        resultData.showAddcard = true
        let rules = { text: "快递配送", children: [] };
        //validate_same_row
        rules.children.text = "运费";
        let child3 = { text: "运费", children: [] };
        if (res.data.validate_same_row[0].delivery_amount == "0") {
          resultData.deliveryInfo.sendTypeStr = "快递配送 免运费";
          resultData.deliveryInfo.deliveryAmount = 0;
        } else {
          resultData.deliveryInfo.sendTypeStr =
            "快递配送 运费" + "" + res.data.validate_same_row[0].delivery_amount + " 点"; //res.data.validate_same_row[0].delivery_amount
          resultData.deliveryInfo.deliveryAmount = res.data.validate_same_row[0].delivery_amount;
        }
        resultData.sendSelectType = 2;
        res.data.validate_same_row.forEach((element) => {
          let children = { text: "" };
          if (element.delivery_amount == "0") {
            children.text = "免运费";
          } else {
            children.text = element.delivery_amount + '点'; //element.delivery_amount
          }
          child3.children.push(children);
        });
        rules.children.push(child3);
        resultData.deliveryRules.push(rules);
      }

    }
    if (res.data.can_take == "1") {
      if (res.data.validate_take_dates && res.data.validate_take_dates.length > 0) {
        resultData.showAddcard = false
        let rules1 = { text: "门店自提", children: [] };
        //validate_take_dates
        res.data.validate_take_dates.forEach((element) => {
          //validate_take_dates
          let child = { text: "", children: [] };
          child.text = element.date;
          child.value = element.date;
          let time = element.validate_take_times; //validate_take_times
          if (time) {
            time.forEach((element) => {
              let children = { text: "" };
              children.text = element;
              child.children.push(children);
            });
          }
          rules1.children.push(child);
        });
        resultData.deliveryInfo.sendTypeStr =
          "门店自提 " + rules1.children[0].text + " " + rules1.children[0].children[0].text;
        resultData.deliveryInfo.shipDate = rules1.children[0].value;
        resultData.deliveryInfo.shipTimeText = rules1.children[0].children[0].text;
        resultData.sendSelectType = 1;
        resultData.deliveryRules.push(rules1);
      }
      // const shopInfo = await getShopInfo(formData.cityCode, brandId);
      // resultData.shopInfo = shopInfo;
    }
    if (res.data.can_ship == "1") {
      if (res.data.validate_delivery_dates && res.data.validate_delivery_dates.length > 0) {
        resultData.showAddcard = false
        let rules2 = { text: "商家配送", children: [] };
        resultData.validate_delivery_dates = res.data.validate_delivery_dates
        res.data.validate_delivery_dates.forEach((element) => {
          let child = { text: "", children: [] };
          child.text = element.date;
          child.value = element.delivery_amount;
          let time2 = JSON.parse(element.validate_delivery_times);
          if (time2) {
            time2.forEach((element) => {
              let children = { text: "" };
              children.text = element;
              child.children.push(children);
            });
          }
          rules2.children.push(child);
        });

        if (res.data.validate_delivery_dates[0].delivery_amount == "0") {
          resultData.deliveryInfo.sendTypeStr =
            "商家配送 " + rules2.children[0].text + " " + rules2.children[0].children[0].text + ' 免运费';


        } else {
          resultData.deliveryInfo.sendTypeStr =
            "商家配送 " + rules2.children[0].text + " " + rules2.children[0].children[0].text + ' 运费' + res.data.validate_delivery_dates[0].delivery_amount + '点';
        }
        resultData.deliveryInfo.shipDate = rules2.children[0].text;
        resultData.deliveryInfo.shipTimeText = rules2.children[0].children[0].text;
        resultData.deliveryInfo.deliveryAmount = res.data.validate_delivery_dates[0].delivery_amount;
        resultData.sendSelectType = 3;
        resultData.deliveryRules.push(rules2);
      }

    }
  }
  if (resultData.deliveryRules.length == 0 || resultData.isDistribution == 0) {
    return false
  } else
    return resultData;
  // this.setSendTypeTitle(false);
}