<template>
  <div>
    <van-popup v-model="popupShow" @closed="popupShow = false" position="bottom" round closeable safe-area-inset-bottom
      :style="{ height: '60%' }">
      <div class="address-list">
        <van-radio-group v-model="radio" @change="handleRadioGroupChange">
          <div class="address-item" :class="{ 'selected-address-item': radio === item.id }" v-for="item in addressList"
            :key="item.id">
            <van-radio :name="item.id" checked-color="#eea20a">
              <div class="address-item-wrapper">
                <div class="address-item-content">
                  <div class="address-item-header">
                    <span>{{ item.recipient }}</span>
                    <span>{{ getPhone(item.recipientMobile) }}</span>
                    <span class="address-item-header-tag" v-if="item.defaultFlag == '1'">默认</span>
                  </div>
                  <div class="address-item-text">
                    <span>{{ item.addressCname }}{{ item.addressQname }}{{ item.addressPname }}</span>
                    {{ item.address }} {{ item.houseNum }}
                  </div>
                </div>
              </div>
            </van-radio>
          </div>
        </van-radio-group>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { addCakeAddress, addressTag } from "./index.action";
export default {
  props: {},
  data() {
    return {
      addressList: [], // 地址信息list
      userId: "", // 用户id
      popupShow: false,
      addressInfo: {
        address: "",
        consigneeAddress: "",
        consigneeName: "",
        consigneePhone: "",
        addressId: "",
      }, // 收货地址信息
      radio: "1",
      list: [
        {
          id: "1",
          name: "张三",
          tel: "13000000000",
          address: "浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室",
          isDefault: true
        },
        {
          id: "2",
          name: "李四",
          tel: "1310000000",
          address: "浙江省杭州市拱墅区莫干山路 50 号"
        }
      ]
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.userId = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : "";
      if (this.addressList && this.addressList.length <= 0) {
        await this.getAddress();
      }
    },
    open(val) {
      if (!(Array.isArray(this.addressList) && this.addressList.length > 0)) {
        if (val && val === 1) {
          addressTag()
        } else
          this.$router.push("/EditAddress");
      } else {
        this.popupOnOff(true);
      }
    },
    async handleRadioGroupChange(name) {
      const addressItem = this.addressList.find((item) => item.id == name);
      const addressItemRes = await this.setAddresInfo(addressItem);
      this.popupOnOff();
      this.$emit("change", { addressInfo: this.addressInfo, target: addressItemRes });
    },

    /**
     * 设置选择城市配置信息，
     * 更新城市列表的dsUserId 以及dsId值
     */
    async setAddresInfo(record) {

      if (record) {
        const { id, addressCname, addressQname, addressPname, address, recipient, recipientMobile, houseNum } = record;
        this.addressInfo.address = addressCname + addressQname + addressPname + address + houseNum;
        this.addressInfo.consigneeAddress = addressPname + address + houseNum;
        this.addressInfo.consigneeName = recipient;
        this.addressInfo.consigneePhone = recipientMobile;
        this.addressInfo.addressId = id;
        const addCakeAddressRes = await addCakeAddress(record);
        this.addressList.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              dsUserId: addCakeAddressRes.dsUserId,
              dsId: addCakeAddressRes.dsId
            };
          }
          return item;
        });
        let addressinfo = this.addressList.find((item) => item.id === id)
        addressinfo.dsUserId = addCakeAddressRes.dsUserId
        addressinfo.dsId = addCakeAddressRes.dsId
        return addressinfo;
      } else {
        this.addressInfo.address = "暂无收获地址点击添加地址";
      }
    },

    // 获取地址列表数据
    async getAddress() {
      const res = await this.$api.getAddress(this.userId);
      if (res.code == 1) {
        Toast(res.msg);
        return;
      }
      if (Array.isArray(res.data) && res.data.length > 0) {
        this.addressList = res.data;
        const defaultAddress = res.data.find((item) => Number(item.defaultFlag) === 1) || res.data[0];
        const addressItemRes = await this.setAddresInfo(defaultAddress);
        this.$emit("loadAfter", { addressInfo: this.addressInfo, target: addressItemRes });
      } else {
        const addressItemRes = await this.setAddresInfo();
        this.$emit("loadAfter", { addressInfo: this.addressInfo, target: addressItemRes });
      }
    },
    /**
     * 城市选择popup开关
     */
    popupOnOff(flag) {
      this.popupShow = !!flag;
    },
    getPhone(phone) {
      return this.$utils.formPhone(phone);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./index";
</style>
