<template>
  <div class="ticket-list-wrapper">

    <div class="ticket-title">场次</div>
    <div class="ticket-item-wrapper">
      <div :class="item.checked ? 'ticket-top-item-selected' : 'ticket-top-item'" v-for="(item, index ) in venueList"
        :key="item.id" @click="() => dataClick(index)">
        <div class="ticket-item-content">
          <div>{{ item.ymd }}</div>
          <div>{{ item.weekTime }}</div>
        </div>
      </div>
    </div>
    <div class="ticket-title">票面</div>
    <div class="ticket-item-wrapper">
      <div
        :class="selectedSpecData.id === item.id ? Number(item.status) !== 3 && Number(item.status) !== 2 ? 'ticket-item-selected' : 'ticket-item-cantselected' : 'ticket-item'"
        v-for="(item, index ) in PriceList" :key="item.id" @click="() => handleSpecItemClick(item)">
        <div class="ticket-item-content">
          {{ item.ticketPrice }}
        </div>
        <span class="right-top-tip" v-if="Number(item.status) === 3">补货中</span>
        <span class="right-top-tip" v-if="Number(item.status) === 2">已下架</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketChoose",
  props: {
    venueList: {
      type: Array
    }
  },
  data() {
    return {
      selectedSpecData: {},
      PriceList: []
    };
  },
  computed: {
  },
  watch: {
    // venueList: {
    //   handler: function (newVal) {
    //     this.setSelectedSpecData(newVal, "loadAfter");
    //   },
    //   immediate: true
    // }
  },
  mounted() {
    if (this.venueList && this.venueList.length > 0) {
      if (this.venueList[0].ticketPriceList && this.venueList[0].ticketPriceList.length > 0) {
        this.PriceList = this.venueList[0].ticketPriceList
        this.setCanBuyItem()
      }
    }
  },
  methods: {
    handleSpecItemClick(record) {
      if (Number(record.status) !== 3 && Number(record.status) !== 2) {
        this.setCanBuyItem(record);
      }
    },
    dataClick(int) {
      this.venueList.forEach((item, index) => {
        if (index === int) {
          item.checked = true
          this.PriceList = item.ticketPriceList
          this.setCanBuyItem()
        } else {
          item.checked = false
        }
        this.$set(this.venueList, index, item);
      });
    },
    setCanBuyItem(item) {
      if (this.PriceList && this.PriceList.length > 0) {
        let dataitem = item
        this.PriceList.forEach(element => {
          if (!dataitem && element.status !== 2 && element.status !== 3) {
            dataitem = element
          }
        });
        this.setSelectedSpecData(dataitem)
      }
    },
    setSelectedSpecData(record) {
      let specData = null;
      if (!record) return;
      if (Array.isArray(record) && record.length > 0) {
        specData = record[0];
      } else {
        specData = record;
      }
      this.selectedSpecData = specData;
      this.$emit('change', specData);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
